import Webcam from "react-webcam";
import { useCallback, useRef, useState } from "react"; // import useCallback

const CustomWebcam = ({imgSrc, webcamRef, canvasRef}) => {
    // const webcamRef = useRef(null); // create a webcam reference
    // // const [imgSrc, setImgSrc] = useState(null); // initialize it

    // const capture = useCallback(() => {
    //     const imageSrc = webcamRef.current.getScreenshot();
    //     console.log(imageSrc);
    //     setImgSrc(imageSrc);
    // }, [webcamRef]);

    // const retake = () => {
    //     setImgSrc(null);
    // };

    const videoConstraints = {
        width: { min: 1024 },
        height: { min: 720 },
        aspectRatio: 1.422,
        facingMode: "user"
    };
    

    return (
        <div className="container">
            
            {imgSrc ? (
                <img src={imgSrc} alt="webcam" className="object-contain w-full h-full rounded-2xl" />
                
            ) : (
                // <Webcam  ref={webcamRef} 
                //     forceScreenshotSourceSize
                
                <Webcam
                audio={false}
                // screenshotFormat="image/jpeg"
                    forceScreenshotSourceSize={true}
                    ref={webcamRef}
                    className="w-full h-full"
                />


            )}
        </div>
    );
};

export default CustomWebcam;