import "react-multi-carousel/lib/styles.css";

import {
  faCamera,
  faCheck,
  faDownload,
  faEdit,
  faFemale,
  faGreaterThan,
  faLessThan,
  faMale,
  faSearch,
  faShare,
  faShareAlt,
  faUpload,
  faUserEdit,
} from "@fortawesome/fontawesome-free-solid";
import { useCallback, useRef, useState } from "react";

import { Buffer } from 'buffer';
import Carousel from "react-multi-carousel";
import CustomWebcam from "./CustomWebcam";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Template from "./Template";
import axios from "axios";
import { getCurrentUser } from "../services/auth.service";
import { saveGallery } from "../services/gallery.service";

// import { client } from "@gradio/client";
export default function Generateimage({ type }) {
  const [gender, setGender] = useState(0);
  const [currentTemplate, setCurrentTemplate] = useState(0);

  const inputRef = useRef(null);
  const [sourceimage, setSourceImage] = useState(null);
  const [resultimage, setResultImage] = useState(null);
  const [resultState, setResultState] = useState(0);
  const [quality, setQuality] = useState(2);
  const besaudi_templates = [
    `/img/be_template${gender}0.png`,
    `/img/be_template${gender}1.png`,
    `/img/be_template${gender}2.png`,
    `/img/be_template${gender}3.png`,
    `/img/be_template${gender}4.png`,
    `/img/be_template${gender}5.png`,
    `/img/be_template${gender}6.png`,
    `/img/be_template${gender}7.png`,
  ];
  const livesaudi_templates = [
    "/img/be_template10.png",
    "/img/be_template11.png",
    "/img/be_template12.png",
    "/img/be_template13.png",
    "/img/be_template14.png",
  ];

  let source_base64 = 0,
    target_base64 = 0;

  const convertBlobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result.replace("data:", "").replace(/^.+,/, ""));
      };
      reader.readAsArrayBuffer(blob);
    });

  function handleUpload(event) {
    setSourceImage(event.target.files[0]);
  }

  async function generate() {

    // const reader = new FileReader();
    setResultState(1);
    // reader.onloadend = () => {
    // source_base64 = reader.result.replace("data:", "").replace(/^.+,/, "");

    // console.log(file);
    // Convert Base64 to Blob
    const temp = sourceimage.slice(23);
    // console.log(typeof(temp));
    const blob = base64ToBlob(temp, "image/webp");

    const formData = {
      image: blob,
      prompt: "Create a digital rendering of the same person's face with enhanced teeth using orthodontics, crowns, or veneers. Ensure the new teeth appear straighter, slightly whiter, and more aesthetically pleasing while maintaining a natural look. Avoid an overly artificial appearance; the aim is to achieve a subtle enhancement rather than an exaggerated transformation",
      search_prompt: "teeth",
      output_format: "webp"
    };


    const credits = ["Bearer sk-zVm8uCXAFgMqs0TmDFEdsrExjstqh5TjRzangTH3DBpjxB6C",
      "Bearer sk-HdAfu892H2STrpkug6To0X8wlh6ktF65tSOlniFKqtU1bzEX",
      "Bearer sk-wZBCTGgwO5rO8HuPk6vBGr0wU3NuMTfIKAwRAWg81z241D3Q"];
    let i = 0;
    while (i < 3) {
      const response = await axios.postForm(
        `https://api.stability.ai/v2beta/stable-image/edit/search-and-replace`,
        axios.toFormData(formData, new FormData()),
        {
          validateStatus: undefined,
          responseType: "arraybuffer",
          headers: {
            Authorization: credits[i],
            Accept: "image/*"
          },
        },
      );

      if (response.status === 200) {
        // console.log(response.data);
        target_base64 = Buffer.from(response.data).toString('base64');
        // console.log(target_base64);
        setResultImage("data:image/png;base64," + target_base64);
        setResultState(2);
        return;

      } else {
        // throw new Error(`${response.status}: ${response.data.toString()}`);
        i = i + 1;
      }
    }
    return;
    const data = new FormData();

    data.append("image", blob);
    data.append("prompt", "Healthy, well organized");
    data.append("negative_prompt", "Decayed, discolored teeth, missing teeth, eroded enamel, inflamed gums");
    data.append("strength", "0.6");
    data.append("guidance_scale", "12");
    data.append("num_inference_steps", "30");
    data.append("manual_seed", "34234234");

    fetch(`https://0kv9qnac1dj8kz-5000.proxy.runpod.net/inpait`, {
      method: 'post',
      body: data,
    })
      .then((res) => res.blob())
      .then(async (response) => {
        target_base64 = await convertBlobToBase64(response);
        // console.log(target_base64);
        setResultImage("data:image/png;base64," + target_base64);
        setResultState(2);
      })



  }
  function base64ToBlob(base64String, contentType = "") {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }

    const byteArray = new Uint8Array(byteArrays);
    return new Blob([byteArray], { type: contentType });
  }

  const downloadFile = (file) => {
    const contentType = "image/png";
    // console.log(file);
    // Convert Base64 to Blob
    const blob = base64ToBlob(file.slice(22), contentType);
    // console.log(blob);
    const fileURL = window.URL.createObjectURL(blob);
    // console.log(fileURL);
    let alink = document.createElement("a");
    alink.href = fileURL;
    alink.download = "image.png";
    alink.click();
  };

  const webcamRef = useRef(null); // create a webcam reference
  // const [imgSrc, setImgSrc] = useState(null); // initialize it

  const canvasRef = useRef(null);


  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    const video = webcamRef.current.video;

    // console.log(imageSrc);
    setSourceImage(imageSrc);
    return;
    console.log(canvasRef);
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);


  }, [webcamRef]);

  const retake = () => {
    setSourceImage(null);
  };


  return (
    <div className="mt-14 -mx-10">
      <div className="relative md:flex md:gap-10 justify-center gap-5">
        <div className="relative flex align-middle md:w-2/5 rounded-2xl upload-image max-md:aspect-video max-h-[600px]">
          <CustomWebcam imgSrc={sourceimage} webcamRef={webcamRef} canvasRef={canvasRef} />

          {/* <input
            type="file"
            ref={inputRef}
            className="hidden"
            onChange={handleUpload}
            alt="carimg"
            accept="image/*"
          />

          


          {sourceimage && (
            <div className="absolute top-10 right-5">
              <FontAwesomeIcon
                icon={faUserEdit}
                className="size-10 right-4 hover:cursor-pointer text-[#120f1b]"
                onClick={() => inputRef.current.click()}
              />
            </div>
          )}
          {sourceimage ? (
            <img
              src={URL.createObjectURL(sourceimage)}
              alt="sdf"
              className="object-contain w-full h-full rounded-2xl"
            />
          ) : (
            <div className="flex flex-col items-center justify-center w-1/2 h-full gap-5 mx-auto my-auto text-white">
              <FontAwesomeIcon
                icon={faUpload}
                className="mx-auto size-10 hidden"
              />
              <p className="text-xl hidden">
                <b>Click to upload</b> or drag and drop
              </p>
              <p className="text-lg hidden">Max. File Size: 30MB</p>
              <button
                className="button"
                onClick={() => inputRef.current.click()}
              >
                <FontAwesomeIcon icon={faCamera} />
                &nbsp;&nbsp;&nbsp;Take Photo
              </button>
            </div>
          )}
         */}
        </div>




        <div className=" justify-center w-full mt-10 px-4 gap-10 md:hidden flex max-md:text-sm">

          {sourceimage ? (
            <button
              className="w-1/3 my-auto align-middle button"
              onClick={retake}
            >
              <FontAwesomeIcon icon={faCamera} className="hidden md:inline-block" />&nbsp;
              Retake
            </button>
          ) : (
            <button
              className="w-1/3 my-auto align-middle button"
              onClick={capture}
            >
              <FontAwesomeIcon icon={faCamera} className="hidden md:inline-block" />&nbsp;
              Capture
            </button>


          )}




          <button
            className="w-1/3 my-auto align-middle button"
            onClick={generate}
          >
            <img
              src="/img/gen_icon.png"
              alt="adf"
              className="hidden mr-2 size-6 md:inline"
            />
            Generate
          </button>
        </div>

        <div className="relative overflow-hidden border-2 md:w-2/5 rounded-2xl max-md:mt-10 max-h-[600px]">

          {false && (
            <div className="absolute top-10 right-5">
              <FontAwesomeIcon
                icon={faShareAlt}
                className="block text-white size-10 right-4 hover:cursor-pointer"
                onClick={() => alert("share please")}
              />
              <FontAwesomeIcon
                icon={faDownload}
                className="block mt-4 text-white size-10 right-4 hover:cursor-pointer"
                onClick={() => downloadFile(resultimage)}
              />
            </div>
          )}

          <img
            src={resultimage ? resultimage : `/img/be_template.png`}
            alt="Teeth swap result"
            className={`w-full h-full object-contain rounded-2xl ${!resultimage && "opacity-0"}`}
          />

          <div className="text-white absolute w-full left-0 top-[45%] text-center text-2xl">

            {resultState === 0 ? (
              "No Image Generated"
            ) : resultState === 1 ? (
              <span>
                {" "}
                <svg
                  className="inline-block w-10 h-10 mr-3 -ml-1 text-white animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Generating ...
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="lg:relative flex w-full h-24 left-0 my-6 rounded-xl justify-center">
        <div className="hidden justify-center w-1/2 px-4 py-6 text-white">
          <div className="content-center justify-center text-2xl align-middle w-full">
            <label className="flex">
              Quality:&nbsp;&nbsp;
              <input
                id="typeinp"
                type="range"
                min="0"
                max="10"
                className="w-full"
                value={quality}
                onChange={(e) => setQuality(e.target.value)}
                step="1"
              />
              &nbsp;&nbsp;&nbsp;{quality}
            </label>
          </div>
        </div>
        <div className=" justify-center w-4/5 px-4 gap-10 md:flex hidden">

          {sourceimage ? (
            <button
              className="w-1/3 my-auto align-middle button"
              onClick={retake}
            >
              <FontAwesomeIcon icon={faCamera} className="hidden md:inline-block" />&nbsp;
              Retake
            </button>
          ) : (
            <button
              className="w-1/3 my-auto align-middle button"
              onClick={capture}
            >
              <FontAwesomeIcon icon={faCamera} className="hidden md:inline-block" />&nbsp;
              Capture
            </button>


          )}




          <button
            className="w-1/3 my-auto align-middle button"
            onClick={generate}
          >
            <img
              src="/img/gen_icon.png"
              alt="adf"
              className="hidden mr-2 size-6 md:inline"
            />
            Generate
          </button>
        </div>
      </div>
      <div className="hidden mt-10 lg:mx-40">
        {/*         
        <Template
          data={type ? livesaudi_templates : besaudi_templates}
          setCurrentTemplate={setCurrentTemplate}
          currentTemplate={currentTemplate}
        />
        
        <div className="flex items-center justify-center bg-gray-700 rounded-md size-8 hover:cursor-pointer">
          <FontAwesomeIcon icon={faLessThan} />
        </div>

        <img
          src={`/img/be_template${type}0.png`}
          alt="Be saudi template"
          className="rounded-md h-1/3 max-lg:hidden"
        />

        <img
          src={`/img/be_template${type}2.png`}
          alt="Be saudi template"
          className="rounded-md h-1/2 max-lg:hidden"
        />

        <img
          src={`/img/be_template${type}1.png`}
          alt="Be saudi template"
          className="rounded-md h-2/3 gradient-border"
        />

        <img
          src={`/img/be_template${type}3.png`}
          alt="Be saudi template"
          className="rounded-md h-1/2 max-lg:hidden"
        />

        <img
          src={`/img/be_template${type}4.png`}
          alt="Be saudi template"
          className="rounded-md h-1/3 max-lg:hidden"
        />

        <div className="flex items-center justify-center bg-gray-700 rounded-md size-8 hover:cursor-pointer">
          <FontAwesomeIcon icon={faGreaterThan} />
        </div> */}
      </div>

      <p className="text-center text-white text-xl">

        Please ensure the image is of a higher resolution than <span className="gradient-text">1024x1024</span> pixels and focuses on a close-up of the face

      </p>
    </div>
  );
}
